import React from 'react';
import styled from 'styled-components';
import {StaticQuery, graphql} from 'gatsby';
import StatusInner from "./StatusInner";

const Holder = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

function Status() {
  return (
    <Holder>
      <Inner>
        <StaticQuery
          query={graphql`
                query StatusQuery {
                  contentfulGlobalSettings(title: {eq: "Global Settings"}) {
                      status
                  }
                }
              `}
          render={data => {
            return (
              <StatusInner contentfulStatus={data.contentfulGlobalSettings.status}/>
            )
          }}
        />
      </Inner>
    </Holder>
  )
}

export default Status;
