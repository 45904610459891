import {createGlobalStyle} from 'styled-components';

import ABCWhyteInktrap2 from './../../assets/fonts/ABCWhyteInktrap-Medium.woff2';
import ABCWhyteInktrap from './../../assets/fonts/ABCWhyteInktrap-Medium.woff';

import GraebenbachMono2 from './../../assets/fonts/GraebenbachMono-Regular.woff2';
import GraebenbachMono from './../../assets/fonts/GraebenbachMono-Regular.woff';

import Graebenbach from './../../assets/fonts/Graebenbach-Regular.woff';
import GraebenbachEot from './../../assets/fonts/Graebenbach-Regular.eot';
import GraebenbachTtf from './../../assets/fonts/Graebenbach-Regular.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "ABCWhyteInktrap";
    font-weight: 500;
    src: url('${ABCWhyteInktrap2}') format("woff2"),
    url('${ABCWhyteInktrap}') format("woff");
  }

  @font-face {
    font-family: "GraebenbachMono";
    font-weight: normal;
    src: url('${GraebenbachMono2}') format("woff2"),
    url('${GraebenbachMono}') format("woff");
  }

  @font-face {
    font-family: "Graebenbach";
    font-weight: normal;
    src: url('${Graebenbach}') format("woff");
  url('${GraebenbachEot}') format("eot");
  url('${GraebenbachTtf}') format("ttf");
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${props => props.theme.fluidType(0)};
    -webkit-tap-highlight-color: transparent;

    * {
      box-sizing: border-box;
    }
  }

  body {
    margin: 0;
    // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
    font-family: "Graebenbach", Helvetica, Arial, sans-serif;
    color: ${props => props.theme.colours.black};
    background-color: ${props => props.theme.colours.cream};
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Eliza', Helvetica, Arial, sans-serif;
    line-height: .95;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.3;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, .h1 {
    ${props => props.theme.fluidType(4)};
  }

  h2, .h2 {
    ${props => props.theme.fluidType(3)};
  }

  h3, .h3 {
    ${props => props.theme.fluidType(2)};
  }

  h4, .h4 {
    ${props => props.theme.fluidType(1)};
  }

  h5, .h5 {
    ${props => props.theme.fluidType(0)};
  }

  h6, .h6 {
    ${props => props.theme.fluidType(-1)};
  }

  .h6-smaller {
    ${props => props.theme.fluidType(-1.8)};
    line-height: 1;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-1.4)};
      line-height: 0.9;
    }
  }

  .eliza-mono {
    font-family: 'Eliza Mono', Helvetica, Arial, sans-serif !important;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small {
    display: block;
  }

  small, p.small, li.small {
    ${props => props.theme.fluidType(-2)};
  }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  em, i {
    font-style: italic;
  }

  strong, b {
    font-weight: bold;
  }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub {
    top: 0.4em;
  }

  label {
    ${props => props.theme.fluidType(-1)};
    line-height: 1.2;
    font-weight: normal;
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    width: 100%;
    display: block;
    font-family: inherit;
    ${props => props.theme.fluidType(0)};
    padding: 0.2rem 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid;
    border-radius: 2px;
    line-height: 1.2;
    background-color: transparent;
    -webkit-appearance: none;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;
    cursor: pointer;
    padding: 0 1rem;
    font-family: inherit;
    ${props => props.theme.fluidType(0)};
    line-height: 2;
    text-decoration: none;
    white-space: nowrap;
    border: 1px solid;
    border-radius: 2px;

    color: inherit;
    background-color: transparent;

    &:hover {
      text-decoration: none;
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
      line-height: inherit;
      font-size: inherit;
    }

    &.fancy {
      border: 10px solid ${props => props.theme.colours.blue};
      background-colour: inherit;
      line-height: 1.05;
      border-radius: 2rem;

      &.yellow {
        background-color: ${props => props.theme.colours.yellow};
      }

      &.orange {
        background-color: ${props => props.theme.colours.orange};
      }
    }

    &.nav {
      height: auto;
      margin: 0;
      padding: 5px 10px;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
    }

    &.icon-smallest {
      border: none;

      svg {
        height: 0.5rem;
        width: auto;
        display: inline-block;
      }
    }

    &.icon-small {
      border: none;

      svg {
        height: 0.6rem;
        width: auto;
        display: inline-block;
      }
    }

    &.icon-large {
      border: none;

      svg {
        height: 1rem;
        width: auto;
        display: inline-block;
      }
    }

    &.lang {
      background-color: ${props => props.theme.colours.black};
      height: 100%;
      padding: 0 1rem;
      border-radius: 3rem;

      &:hover, &:focus {
        background-color: ${props => props.theme.colours.blue};

        svg {
          path {
            fill: ${props => props.theme.colours.pink} !important;
          }
        }
      }
    }
  }

  .errorMsg {
    color: ${props => props.theme.colours.red};
  }

  .en {
    color: ${props => props.theme.colours.orange};

    &:hover {
      background-color: ${props => props.theme.colours.orange} !important;
    }
  }

  .ko {
    color: ${props => props.theme.colours.pink};

    &:hover {
      background-color: ${props => props.theme.colours.pink} !important;
    }
  }

  .jp {
    color: ${props => props.theme.colours.maroon};

    &:hover {
      background-color: ${props => props.theme.colours.maroon} !important;
    }
  }

  .chn {
    color: ${props => props.theme.colours.blue};

    &:hover {
      background-color: ${props => props.theme.colours.blue} !important;
    }
  }

  .desktop {
    display: none;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: block;
    }
  }

  .mobile {
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: none;
    }
  }
`;

export default GlobalStyle;
