import React from "react"
import {CSSTransition, TransitionGroup} from "react-transition-group";
import styled from 'styled-components';

//This variable will be responsible for our animation duration
const timeout = 500;

const TransitionHolder = styled.div`
  position: relative;
`;

const PageHolder = styled.div`
  width: 100%;
  //overflow: hidden;
  &.page-appear,
  &.page-enter {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;

    .content-holder.from-bottom {
      transform: translateY(100%);
    }

    .content-holder.from-left {
      transform: translateX(-100%);
    }

    .content-holder.from-right {
      transform: translateX(100%);
    }
  }

  &.page-appear-active, &.page-appear-done,
  &.page-enter-active, &.page-enter-done {
    .content-holder.from-bottom {
      transform: translateY(0);
      transition: transform ${timeout}ms ${timeout / 2}ms;
    }

    .content-holder.from-right, .content-holder.from-left {
      transform: translateX(0);
      transition: transform ${timeout}ms ${timeout / 2}ms;
    }
  }

  //&.page-exit,
  //&.page-exit-active {
  //  .content-holder{
  //    z-index: -1;
  //  }
  //}
`;

class Transition extends React.PureComponent {
    render() {
        //Destructuring props to avoid garbage this.props... in return statement
        const {children, location} = this.props;

        return (
            //Using TransitionGroup and CSSTransition which are both
            //coming from  'react-transition-group' and are required for transitions to work
            <TransitionHolder>
                <TransitionGroup>
                    <CSSTransition
                        key={location.pathname}
                        timeout={timeout * 1.5}
                        classNames="page"
                    >
                        <PageHolder path={location.pathname}>{children}</PageHolder>
                    </CSSTransition>
                </TransitionGroup>
            </TransitionHolder>
        )
    }
}

export default Transition
