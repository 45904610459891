import React from 'react';
import styled from "styled-components";

const DetailsHolder = styled.div`
  ${props => props.theme.fluidType(-2.4)};
  padding: 3rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(-1.4)};
    padding: 3rem 0 2rem 0;
  }
`;


function WeirdDetailsHolder({children}) {
    return (
        <DetailsHolder>{children}</DetailsHolder>
    );
}

export default WeirdDetailsHolder;