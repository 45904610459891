import React from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";
import MobileButtons from "./MobileButtons2";
import ComingSoon from "../atoms/ComingSoon";
import {useStore} from "../../utils/store";

const Holder = styled.div`
  position: fixed;
  height: calc(100% - (${props => props.height} + 1.75rem));
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.colours.cream};
  z-index: 20;
  transform: ${props => props.show ? 'translateY(0%)' : 'translateY(100%)'};
  transition: transform 0.25s ease-in-out;
`

export default function MenuOverlay2({show, hide, multiFunction}) {
    const height = useStore((state) => state.height)

    return (
        <Holder height={height} show={show}>
            <ComingSoon bg="yellow" border="blue"/>
            <MobileButtons hide={hide} multiFunction={multiFunction}/>
        </Holder>
    )
}

MenuOverlay2.propTypes = {
    hide: PropTypes.func.isRequired,
    multiFunction: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
};
