import React, {useEffect, useState} from 'react';
import Star from "../../assets/navy-star.inline.svg";
import styled from "styled-components";

const Small = styled.small`
  display: flex;
  align-items: center;
  gap: 0.15rem;

  svg {
    height: 0.25rem;
    width: auto;
    object-fit: cover;

    path {
      fill: ${props => props.theme.colours.black};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        fill: ${props => props.theme.colours.maroon};
      }
    }
  }
`

function Temperature({count, locales}) {
  const [country, setCountry] = useState(null)
  const [temp, setTemp] = useState(null)

  useEffect(() => {

    fetch(`https://api.openweathermap.org/data/2.5/weather?id=${locales[count][1]}&units=metric&appid=${process.env.GATSBY_OPENWEATHER_API_KEY}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
      .then(response => response.json())
      .then(data => {
        if (data.main) {
          setCountry((data.name))
          setTemp((data.main.temp).toFixed(1))
        } else {
          setTemp(null)
          setCountry('Melbourne')
        }
      })
      .catch(error => {
        setTemp(null);
        setCountry('Melbourne')
      })
  }, [count, locales])

  if (!temp) return <Small>loading...</Small>

  return (
    <Small>{country} <Star /> {temp}°C</Small>
  )
}

export default Temperature;
