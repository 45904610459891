import React from 'react';
import PropTypes from 'prop-types';

import Transition from '../components/atoms/Transition';
import '../utils/fontface.css';

import GlobalStyles from '../components/atoms/GlobalStyles';
import {ThemeProvider} from 'styled-components';
import {theme} from '../utils/styling';
import ColourSchemes from '../components/organisms/ColourSchemes';

function Index(props) {

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <ColourSchemes path={props.location.pathname}>
                <Transition location={props.location}>
                    <main>{props.children}</main>
                </Transition>
            </ColourSchemes>
        </ThemeProvider>
    )
}


Index.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Index;
