import React from 'react';
import styled from "styled-components";

const ListHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: block;
  }

  ul {
    margin-top: 0.5rem;
    list-style-type: none;
    padding-left: 0.5rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin-bottom: 2rem;
    }

    li {
      line-height: 0.9;
      list-style-type: none;
      position: relative; /* It's needed for setting position to absolute in the next rule. */
      ${props => props.theme.fluidType(-2.8)};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(-1.4)};
      }
    }

    li::before {
      content: '■';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: -2em; /* Adjust this value so that it appears where you want. */
      font-size: 0.25rem; /* Adjust this value so that it appears what size you want. */
    }

    p {
      font-family: 'Graebenbach', Helvetica, Arial, sans-serif;
    }
  }
}

p {
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
  line-height: 1;
  margin: 0;
  ${props => props.theme.fluidType(-2.8)};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${props => props.theme.fluidType(-1.4)};
  }
}
`;

function WeirdListHolder({children}) {
    return (
        <ListHolder>{children}</ListHolder>
    );
}

export default WeirdListHolder;