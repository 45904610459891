import React, {useEffect, useState} from 'react';
import styled, {keyframes} from "styled-components";

const flash = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Colon = styled.p`
  margin: 0;
  animation: ${flash} 1s linear infinite;
`

const Small = styled.small`
  display: flex;
  align-items: center;
  margin: 0;
`

function Time({count, locales}) {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const date = Date.now()
    const options = {
      hour: 'numeric', minute: 'numeric',
      timeZone: locales[count][0],
      timeZoneName: 'short'
    };
    const intl = new Intl.DateTimeFormat('en-AU', options).formatToParts(date)
    // console.log(intl)
    setTime(intl)
  }, [count, locales]);

  if (!time) return <Small>loading...</Small>

  return (
    <Small>{time[0].value}<Colon>:</Colon>{time[2].value}{time[4].value} 【{time[6].value}】</Small>
  )
}

export default Time;
