import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Logo from '../atoms/Logo';
import {Link} from 'gatsby';
import Time from '../atoms/Time';
import Temperature from '../atoms/Temperature';
import DatePrintout from '../atoms/DatePrintout';
import Status from '../atoms/Status';

const Holder = styled.header`
  font-family: 'ABCWhyteInktrap', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-feature-settings: 'tnum';

  small {
    margin: 0;
    ${props => props.theme.fluidType(-2.7)};
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.fluidType(-2.1)};
    }
  }
`;

const Banner = styled.div`
  display: grid;
  padding: 0 1rem;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.headerBg};
  @media ( ${props => props.theme.breakpoints.sm} ) {
    > * {
      min-width: 90px;
    }

    > :nth-child(5) {
      min-width: 170px;
    }

    > :nth-child(3) {
      min-width: 150px;
    }
  }

  > :nth-child(2) {
    text-align: center;
  }

  > :nth-child(3) {
    text-align: right;
  }

  > :nth-child(4),
  > :nth-child(5),
  > :nth-child(6) {
    display: none;
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    color: ${props => props.theme.colours.maroon};
    padding: 0rem 1rem;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 3fr 4.5fr;
    > :nth-child(4) {
      justify-self: center;
      display: block;

      svg {
        display: block;
        height: 20px;

        .circle-colour {
          fill: ${props => props.theme.logo} !important;
        }
      }
    }

    > :nth-child(4) {
      display: block;
    }

    > :nth-child(5),
    > :nth-child(6) {
      display: flex;
    }
  }
`;

const PostBanner = styled.div`
  margin-bottom: 0.5rem;
  display: grid;
  padding: 0 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: ${props => props.theme.headerBg};


  svg {
    display: block;
    height: 20px;
    width: auto;

    .circle-colour {
      fill: ${props => props.theme.logo} !important;
    }
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }
`;

const locales = [
    ['Australia/Melbourne', 2158177],
    ['Asia/Seoul', 1835848],
    ['Asia/Tokyo', 1850144],
    ['Asia/Singapore', 1880252],
]

function Header() {
    const [count, setCount] = useState(0)

    useEffect(() => {
        const interval = setTimeout(() => {
            if (count === locales.length - 1) {
                setCount(0)
            } else {
                setCount((count) => count + 1)
            }
        }, 5000)
        return () => clearTimeout(interval)
    }, [count])

    return (
        <Holder>
            <div>
                <PostBanner>
                    <Link to="/">
                        <Logo/>
                    </Link>
                    <Status/>
                </PostBanner>
                <Banner>
                    <DatePrintout/>
                    <Temperature count={count} locales={locales}/>
                    <Time count={count} locales={locales}/>
                    <Link to="/">
                        <Logo/>
                    </Link>
                    <Status/>
                </Banner>
            </div>
        </Holder>
    )
}

export default Header
