import { createStore as reduxCreateStore } from "redux";

const reducer = (state, action) => {
  if (action.type === `SET_INFO`) {
    return {
      info: action.info,
    }
  }
  return state
};

const initialState = {
  info: false,
};

let createStore = () => reduxCreateStore(
  reducer,
  initialState
);

if (typeof window !== `undefined`) {
  createStore = () => reduxCreateStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
  );
}

export default createStore;
