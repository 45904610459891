exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-files-js": () => import("./../../../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ttm-js": () => import("./../../../src/pages/ttm.js" /* webpackChunkName: "component---src-pages-ttm-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/Articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-journal-list-page-js": () => import("./../../../src/templates/JournalListPage.js" /* webpackChunkName: "component---src-templates-journal-list-page-js" */),
  "component---src-templates-project-list-page-js": () => import("./../../../src/templates/ProjectListPage.js" /* webpackChunkName: "component---src-templates-project-list-page-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

