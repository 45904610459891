import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import InfoContent from './InfoContent';
import {CSSTransition} from 'react-transition-group';
import {useStore} from "../../utils/store";

const Holder = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 90;

  &.info-appear,
  &.info-enter {
    transform: translateY(100%);
  }

  &.info-appear-active, &.info-appear-done,
  &.info-enter-active, &.info-enter-done {
    transform: translateY(calc(0% + (${props => props.height} + 1.75rem)));
    transition: transform 500ms;
  }

  &.info-exit {
    transform: translateY(calc(0% + (${props => props.height} + 1.75rem)));
    transition: transform 500ms;
  }

  &.info-exit-active {
    transform: translateY(100%);
    transition: transform 500ms;
  }
`;

function InfoHolder({info, toggleInfo}) {
    const height = useStore((state) => state.height)
    return (
        <CSSTransition
            mountOnEnter
            unmountOnExit
            appear
            in={info}
            timeout={500}
            classNames="info">
            <Holder height={height}>
                <InfoContent toggleInfo={() => toggleInfo(false)}/>
            </Holder>
        </CSSTransition>
    )
}

InfoHolder.propTypes = {
    info: PropTypes.bool.isRequired,
    toggleInfo: PropTypes.func.isRequired,
};

const mapStateToProps = ({info}) => {
    return {info};
};

const mapDispatchToProps = (dispatch) => {
    return {toggleInfo: (info) => dispatch({type: `SET_INFO`, info: info})};
};

const ConnectedInfoHolder = connect(
    mapStateToProps,
    mapDispatchToProps
)(InfoHolder);

export default ConnectedInfoHolder;
