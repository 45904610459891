import React, {useState} from 'react';
import styled from 'styled-components';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Arrow from '../../assets/diagonal-arrow.inline.svg';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-auto-flow: dense;

  button {
    grid-column: 2/3;
    grid-row: 1/2;

    svg {
      display: block;

      path {
        fill: ${props => props.theme.footerText};
      }
    }
  }

  .field-holder {
    grid-column: 1/3;
    grid-row: 2/3;

    p {
      margin: 0;
      ${props => props.theme.fluidType(-1.5)};
    }
  }

  input[type="email"] {
    margin: 0;
    padding: 0.25rem;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.footerText};
    ${props => props.theme.fluidType(-1.5)};
  }
`;

const Message = styled.div`
  width: 20rem;
`;

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Enter a Valid Email!')
        .required('Email is Required!'),
});

function SubscribeForm() {

    const [data, setData] = useState(null);

    if (data) {
        return (
            <Message>
                <p dangerouslySetInnerHTML={{__html: data.msg}}/>
            </Message>
        )
    }

    return (
        <Formik
            initialValues={{email: ''}}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    setSubmitting(false);
                    addToMailchimp(values.email)
                        .then(data => {
                            setData(data);
                        })

                }, 400);
            }}
        >
            {({isSubmitting}) => (
                <Form>
                    <Holder>
                        <small>Sign up to our newsletter</small>
                        <div className='field-holder'>
                            <Field className="small" aria-label="email" type="email" name="email"/>
                            <ErrorMessage name="email" component="p"/>
                        </div>
                        <button className="icon-small" type="submit" disabled={isSubmitting}><Arrow/>
                        </button>
                    </Holder>
                </Form>
            )}
        </Formik>
    )
}

export default SubscribeForm;
