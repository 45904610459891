import React from 'react'
import styled from "styled-components";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import IconCross from "../atoms/iconCross";

const Holder = styled.div`
  height: 100%;
  padding: 1rem;
`

const List = styled.ul`
  height: inherit;
  display: flex;
  flex-direction: column;
  gap: 0.5rem !important;

  li:last-child {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    width: 100%;

    .overlay-button {
      padding: 0 1.75rem;
      margin: 0;
      border-radius: 5rem;
      border: none;
      line-height: 1.75;
      ${props => props.theme.fluidType(4)};
    }

    a {
      padding: 0 !important;
      margin: 0 !important;
    }

    .home {
      color: ${props => props.theme.colours.cream};
      background-color: ${props => props.theme.colours.maroon};
    }

    .grey {
      ${props => props.theme.fluidType(1)};
      color: ${props => props.theme.colours.black};
      background-color: ${props => props.theme.colours.grey};
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
  }
`

export default function MobileButtons({hide, multiFunction}) {

    return (
        <Holder>
            <List>
                <li>
                    <Link to="/work">
                        <button className="button overlay-button work" onClick={hide}>Work</button>
                    </Link>
                </li>
                <li>
                    <button className="button overlay-button info" onClick={multiFunction}>Info</button>
                </li>
                <li>
                    <Link to="/journal">
                        <button className="button overlay-button journal" onClick={hide}>Journal</button>
                    </Link>
                </li>
                <li>
                    <Link to="/files">
                        <button className="button overlay-button papers" onClick={hide}>Files</button>
                    </Link>
                </li>
                <li>
                    <Link to="/ttm">
                        <button className="button overlay-button ttm" onClick={hide}>TTM</button>
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        <button className="button overlay-button home" onClick={hide}>Home</button>
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        <button className="button icon-large overlay-button grey eliza-mono"
                                onClick={hide}>Close <IconCross/>
                        </button>
                    </Link>
                </li>
            </List>
        </Holder>
    )
}

MobileButtons.propTypes = {
    hide: PropTypes.func.isRequired,
    multiFunction: PropTypes.func.isRequired,
};
