/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from "./wrap-with-provider";

export const wrapRootElement = wrapWithProvider;

// const transitionDelay = 1000;
// smoothscroll.polyfill();
//
// export const shouldUpdateScroll = ({
//                                 routerProps: { location },
//                                 getSavedScrollPosition,
//                               }) => {
//   if (location.action === 'PUSH') {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: 'smooth'
//     })
//   } else {
//     const savedPosition = getSavedScrollPosition(location);
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false
// };
