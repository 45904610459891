import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import PropTypes from 'prop-types';
import {theme} from '../../utils/styling';
import Footer from '../molecules/Footer';
import ConnectedInfoHolder from './InfoHolder';
import Menu from "../molecules/Menu";

const schemes = {
    home: {
        headerText: theme.colours.maroon,
        headerBg: theme.colours.cream,
        navText: theme.colours.maroon,
        navBg: theme.colours.cream,
        footerText: theme.colours.black,
        footerBg: theme.colours.cream,
        logo: theme.colours.yellow
    },
    info: {
        headerText: theme.colours.peach,
        headerBg: theme.colours.green,
        navText: theme.colours.grey,
        navBg: theme.colours.blue,
        footerText: theme.colours.black,
        footerBg: theme.colours.cream,
        logo: theme.colours.yellow

    },
    journal: {
        headerText: theme.colours.maroon,
        headerBg: theme.colours.peach,
        navText: theme.colours.maroon,
        navBg: theme.colours.peach,
        footerText: theme.colours.black,
        footerBg: theme.colours.peach,
        logo: theme.colours.yellow
    },
    ttm: {
        headerText: theme.colours.maroon,
        headerBg: theme.colours.yellow,
        navText: theme.colours.maroon,
        navBg: theme.colours.yellow,
        footerText: theme.colours.black,
        footerBg: theme.colours.yellow,
        logo: theme.colours.maroon
    },
    files: {
        headerText: theme.colours.maroon,
        headerBg: theme.colours.pink,
        navText: theme.colours.maroon,
        navBg: theme.colours.pink,
        footerText: theme.colours.black,
        footerBg: theme.colours.pink,
        logo: theme.colours.yellow
    },
    projects: {
        headerText: theme.colours.maroon,
        headerBg: theme.colours.orange,
        navText: theme.colours.maroon,
        navBg: theme.colours.orange,
        footerText: theme.colours.black,
        footerBg: theme.colours.orange,
        logo: theme.colours.yellow
    },
};

function ColourSchemes({children, path}) {
    const [scheme, setScheme] = useState(schemes.home);
    const newsDisplay = ['/'];

    useEffect(() => {
        if (path === '/info') {
            setScheme(schemes.info);
        } else if (path.includes('work')) {
            setScheme(schemes.projects);
        } else if (path.includes('project')) {
            setScheme(schemes.projects);
        } else if (path.includes('journal')) {
            setScheme(schemes.journal);
        } else if (path.includes('files')) {
            setScheme(schemes.files);
        } else if (path.includes('ttm')) {
            setScheme(schemes.ttm);
        } else {
            setScheme(schemes.home);
        }
    }, [path]);

    return (
        <ThemeProvider theme={scheme}>
            <div>
                <Menu path={newsDisplay.includes(path)}/>
                {children}
                <ConnectedInfoHolder/>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

ColourSchemes.propTypes = {
    path: PropTypes.string.isRequired
};

ColourSchemes.defaultProps = {
    path: '/'
};

export default ColourSchemes;
