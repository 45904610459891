import React, {useEffect, useState} from "react";
import styled from "styled-components";
import TickerTape from "../molecules/TickerTape";

const Holder = styled.div`
  color: ${props => props.theme.colours.maroon};
  background-color: ${props => props.theme.headerBg};
  margin-top: 0.5rem;
`

const Inner = styled.div`
  display: flex;
  gap: 0.5rem;

  small {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.05rem;
    font-family: 'ABCWhyteInktrap', Helvetica, Arial, sans-serif;
    font-weight: 500;
  }
`

const Dot = styled.small`
  padding: 0 0.8rem;
`

export default function NewsCall() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetch(`https://gnews.io/api/v4/top-headlines?token=${process.env.GATSBY_NEWS_API_KEY}&lang=en`, {
            method: 'GET',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        })
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLoading(false)
            })
            .catch((error) => console.log(error));
    }, []);

    if (!data) return null

    return (
        <Holder>
            <TickerTape speed="30000ms">
                <Inner>
                    {!loading ? data.articles?.map((news) => (<small>{news.title}<Dot>•</Dot></small>)) :
                        <small>loading...</small>}
                </Inner>
            </TickerTape>
        </Holder>
    );
};