import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useWindowSize} from "react-use";
import MenuOverlay from "./MobileOverlay";
import Translate from '../../assets/translate.inline.svg'
import LanguageButtons from "../atoms/LanguageButtons";
import MenuOverlay2 from "./MobileOverlay2";

const Holder = styled.nav`
  margin-top: 0.5rem;
  padding: 0rem 1rem;
  height: 1.8rem;
  width: 100%;
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.navBg};
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
  font-weight: 500;
  @media ( ${props => props.theme.breakpoints.md} ) {
    color: ${props => props.theme.colours.maroon};
    padding: 0 1rem;
    height: unset;
  }

  .lang-holder {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: inherit;
  }

  .menu {
    button {
      padding: 0;
      ${props => props.theme.fluidType(1.6)};
    }
  }

  ul {
    height: 100%;
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: grid;
      grid-template-columns: repeat(5, 1fr) 0.5fr;
      justify-content: unset;
    }
    grid-gap: 1rem;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0;
      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(-2)};
      }

      a, button {
        height: auto;
        margin: 0;
        padding: 1px 10px;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
      }

      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(0)};
        a, button {
          border-radius: 3rem;
        }
      }

      .work {
        background-color: ${props => props.theme.colours.orange};
        @media ( ${props => props.theme.breakpoints.md} ) {
          background-color: unset;
          &:hover {
            background-color: ${props => props.theme.colours.orange};
          }
        }
      }

      .journal {
        background-color: ${props => props.theme.colours.peach};
        @media ( ${props => props.theme.breakpoints.md} ) {
          background-color: unset;
          &:hover {
            background-color: ${props => props.theme.colours.peach};
          }
        }
      }

      .papers {
        background-color: ${props => props.theme.colours.pink};
        @media ( ${props => props.theme.breakpoints.md} ) {
          background-color: unset;
        }

        &:hover {
          background-color: ${props => props.theme.colours.pink};
        }
      }


      .ttm {
        background-color: ${props => props.theme.colours.yellow};
        @media ( ${props => props.theme.breakpoints.md} ) {
          background-color: unset;
        }

        &[aria-current="page"] {
          background-color: ${props => props.theme.colours.maroon};
          color: ${props => props.theme.colours.yellow};
        }

        &:hover {
          background-color: ${props => props.theme.colours.yellow};
          color: ${props => props.theme.colours.maroon};
        }
      }

      .info {
        background-color: ${props => props.theme.colours.blue};
        color: ${props => props.theme.colours.cream};
        @media ( ${props => props.theme.breakpoints.md} ) {
          background-color: unset;
          color: unset;
        }

        &:hover {
          background-color: ${props => props.theme.colours.blue};
          color: ${props => props.theme.colours.cream};
        }
      }

      .close {
        background-color: ${props => props.theme.colours.blue};
        color: ${props => props.theme.colours.cream};
      }

    }

  }
`;

function Navigation({toggleInfo, info}) {
    const size = useWindowSize()
    const [mobile, setMobile] = useState(null)
    const [show, setShow] = useState(false)
    const [mobileShow, setMobileShow] = useState(false)
    const [lang, setLang] = useState(false)

    const toggleMenu = () => {
        setShow(!show);
        setMobileShow(false)
    }

    const toggleLang = () => {
        setShow(false);
        setMobileShow(!mobileShow)
    }

    const handleLang = () => {
        toggleInfo(false);
        setLang(!lang)
    }

    const handleOpen = () => {
        toggleInfo(true);
        setShow(false);
        setMobileShow(false)
    }

    const handleClose = () => {
        toggleInfo(false);
        setShow(false);
        setMobileShow(false)
    }

    useEffect(() => {
        if (size.width < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);

    return (
        <>
            <Holder>
                <ul>
                    {!mobile && <>
                        <li><Link className="work" to="/work" onClick={() => toggleInfo(false)}>Work</Link></li>
                        <li>{!info && <button className="link info" onClick={() => toggleInfo(true)}>Info</button>}
                            {info && <button className="link close" onClick={() => toggleInfo(false)}>Info</button>}
                        </li>
                        <li><Link className="journal" to="/journal" onClick={() => toggleInfo(false)}>Journal</Link>
                        </li>
                        <li><Link className="papers" to="/files" onClick={() => toggleInfo(false)}>Files</Link></li>
                        <li><Link className="ttm" to="/ttm" onClick={() => toggleInfo(false)}>TTM</Link></li>
                        <li className="lang-holder">
                            <button className="button icon-large lang" onClick={handleLang}><Translate/></button>
                            {lang && <LanguageButtons/>}
                        </li>
                    </>
                    }
                    {mobile &&
                        <>
                            <li className="menu">
                                <button className="link eliza-mono" onClick={toggleMenu}>Menu•</button>
                                <MenuOverlay show={show} multiFunction={handleOpen} hide={handleClose}/>
                            </li>
                            <li className="lang-holder">
                                <button className="button icon-large lang" onClick={toggleLang}>
                                    <Translate/>
                                </button>
                                <MenuOverlay2 hide={handleClose} show={mobileShow}
                                              multiFunction={handleOpen}/>
                            </li>
                        </>
                    }
                </ul>
            </Holder>
        </>
    )
}

Navigation.propTypes = {
    info: PropTypes.bool.isRequired,
    toggleInfo: PropTypes.func.isRequired,
};

const mapStateToProps = ({info}) => {
    return {info};
};

const mapDispatchToProps = (dispatch) => {
    return {toggleInfo: (info) => dispatch({type: `SET_INFO`, info: info})};
};

const ConnectedNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);

export default ConnectedNavigation;
