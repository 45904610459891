import React, {useEffect, useState} from 'react';
import NavyStar from '../../assets/navy-star.inline.svg'
import styled from "styled-components";

const Time = styled.small`
  display: flex;
  align-items: center;
  gap: 0.15rem;
  margin: 0;

  svg {
    path {
      fill: ${props => props.theme.colours.black};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        fill: ${props => props.theme.colours.maroon};
      }
    }
  }
`

function DatePrintout() {
    const [date, setDate] = useState('initial');

    const findDate = () => {
        let currentDate = new Date();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        return <Time>{cYear} <NavyStar/> {cMonth} <NavyStar/> {cDay}</Time>;
    };

    useEffect(() => {
        setDate(findDate());
        const dateInterval = setInterval(() => {
            setDate(findDate());
        }, 60000);
        return () => clearInterval(dateInterval);
    }, []);

    return (
        <small>{date}</small>
    )
}

export default DatePrintout;
