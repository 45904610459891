export const theme = {
    colours: {
        white: '#FFF',
        black: '#000',
        maroon: '#510B22',
        cream: '#FFECE1',
        green: '#024100',
        peach: '#FF72EF',
        pink: '#FF00AA',
        red: '#FF0000',
        blue: '#001DFF',
        grey: '#D4D4D4',
        yellow: '#F7C74A',
        orange: '#FF6E00',
        brightGreen: '#65E500',
    },
    typography: {
        min: 20,
        max: 28,
        minScreen: 400,
        maxScreen: 1600,
        scale: {
            min: 1.2,
            max: 1.414,
        }
    },
    // https://www.smashingmagazine.com/2016/05/fluid-typography/
    fluidType: (exp) => {
        return `
      font-size: ${theme.typography.min * Math.pow(theme.typography.scale.min, exp)}px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${theme.typography.min * Math.pow(theme.typography.scale.min, exp)}px + (${theme.typography.max * Math.pow(theme.typography.scale.max, exp)} - ${theme.typography.min * Math.pow(theme.typography.scale.min, exp)})*(100vw - ${theme.typography.minScreen}px)/(${theme.typography.maxScreen} - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${theme.typography.max * Math.pow(theme.typography.scale.max, exp)}px;
      }
      `
    },
    breakpoints: {
        sm: 'min-width: 576px',
        md: 'min-width: 768px',
        lg: 'min-width: 992px',
        xl: `min-width: 1200px`,
    },
};
