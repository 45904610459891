import React, {useEffect, useRef, useState} from 'react'
import ConnectedNavigation from "./Navigation";
import Header from "./Header";
import styled from "styled-components";
import NewsCall from "../atoms/NewsCall";
import {useWindowSize} from "react-use";
import {useStore} from "../../utils/store";

const Holder = styled.div`
  position: fixed;
  z-index: 100;
  top: 1rem;
  left: 0;
  width: 100%;
`

export default function Menu({path}) {
    const [mobile, setMobile] = useState(null)
    const element = useRef(null)
    const size = useWindowSize()
    const setHeight = useStore((state) => state.setHeight)

    useEffect(() => {
        if (size.width < 992) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);

    useEffect(() => {
        if (element.current) {
            const newHeight = element.current.offsetHeight + 'px' // I think offsetHeight is right, maybe clientHeight
            setHeight(newHeight)
            console.log(newHeight)
        }
    }, [size, element, setHeight])

    return (
        <Holder ref={element}>
            <Header/>
            <ConnectedNavigation/>
            {(!mobile && path) && <NewsCall/>}
        </Holder>

    )
}