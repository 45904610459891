import React, {useEffect, useRef, useState} from 'react';
import Container from '../atoms/Container';
import {graphql, StaticQuery} from 'gatsby';
import RichTextConverter from '../atoms/RichTextConverter';
import styled from 'styled-components';
import Logo from '../../assets/combined-logo.inline.svg';
import LogoPlain from '../../assets/logo.inline.svg';
import Square from '../../assets/square.inline.svg';
import Circle from '../../assets/circle.inline.svg';
import Share from '../../assets/share.inline.svg';
import Create from '../../assets/create.inline.svg';
import Embed from '../../assets/embed.inline.svg';
import Implement from '../../assets/implement.inline.svg';
import {GatsbyImage} from "gatsby-plugin-image";
import IconCross from "../atoms/iconCross";
import {useWindowSize} from "react-use";
import WeirdListHolder from "./WeirdListHolder";
import WeirdDetailsHolder from "./WeirdDetailsHolder";

const Holder = styled.div`
  padding-top: 1rem;
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.cream};
  width: 100%;
  min-height: 100%;

  .headline-holder {
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin: 0 6rem;
    }

    h2 {
      font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
      ${props => props.theme.fluidType(1.5)};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(2)};
      }
    }
  }
`;

const LogoHolder = styled.div`
  margin: 6rem 0 4rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 4rem 1rem;
  }

  svg {
    width: 100%;
    height: auto;
  }

  svg:nth-child(2), svg:nth-child(3) {
    display: none;
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 3fr 1.15fr 1.15fr;
    column-gap: 2rem;
    margin: 6rem 6rem 2rem 6rem;
    svg:nth-child(2), svg:nth-child(3) {
      display: block;
    }
  }
`;

const ContentHolder = styled.div`
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    position: relative;
    grid-template-columns: 1fr 4fr;
    column-gap: 1rem;
  }
`;

const DetailsInner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;

  > div {
    width: auto;

    p {
      margin-bottom: 0.5rem;
    }

    p:nth-child(2), p:nth-child(3) {
      margin: 0;
    }
  }
`;


const MainTextHolder = styled.div`
  height: fit-content;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 9fr 1.5fr;
    grid-column: 2;
  }

  > div {
    margin-bottom: 3rem;
    grid-column: 1/2;
  }

  .image-grid {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-template-columns: 3fr 2fr;
      column-gap: 2rem;
      row-gap: unset;
    }

    div {
      padding-left: 32.5%;
      @media ( ${props => props.theme.breakpoints.lg} ) {
        padding: 0;
      }

      .rich-text-holder, p {
        ${props => props.theme.fluidType(-1.6)};
        @media ( ${props => props.theme.breakpoints.lg} ) {
          ${props => props.theme.fluidType(-0.75)};
        }
      }
    }

    div {
      > div:first-child {
        margin-bottom: 3rem;
      }
    }
  }
`;

const BannerImage = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

const Values = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 10rem auto;

  svg {
    height: 100%;
    width: 100%;
  }

  .rich-text-holder, p {
    ${props => props.theme.fluidType(-1.6)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-0.75)};
    }
    margin-right: 1rem;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10rem;

  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding-bottom: 8rem;
  }

  button {
    background-color: ${props => props.theme.colours.orange};
    ${props => props.theme.fluidType(1.25)};
    border: 30px solid ${props => props.theme.colours.peach} !important;
    border-radius: 10rem !important;
    padding: 0.5rem 1rem;
    margin: 4rem 0;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      border: 40px solid ${props => props.theme.colours.peach} !important;
    }
  }

  small {
    padding: 1rem;
    color: ${props => props.theme.colours.black};
  }

  svg {
    width: 14rem;
    height: auto;
  }
`

const FixedHolder = styled.div`
  max-width: ${props => props.theme.typography.maxScreen}px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  float: right;
  bottom: 0;
  top: 0;
  z-index: 50;

  .invert {
    ${props => props.theme.fluidType(-1)};
    height: min-content;
    border-radius: 3rem;
    background-color: ${props => props.theme.colours.grey};
    color: ${props => props.theme.colours.black};
    border: none;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1rem;
  margin-bottom: 3rem;
`

function InfoContent({toggleInfo}) {
    const [offset, setOffset] = useState(0);
    const info = useRef(null);
    const [mobile, setMobile] = useState(null)
    const size = useWindowSize()

    useEffect(() => {
        if (size.width < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);


    useEffect(() => {
        if (info.current) {
            const updateSticky = function () {
                setOffset(info.current.getBoundingClientRect().top.toFixed(2));
            };

            window.addEventListener('resize', updateSticky);
            updateSticky();
            return () => window.removeEventListener('resize', updateSticky);
        }
    }, []);

    // Resolving weird unicode issue [L SEP]
    document.body.innerHTML.replace(/\u2028/g, ' ');

    return (
        <Holder>
            <FixedHolder>
                <button className="button icon-smallest invert eliza-mono" onClick={toggleInfo}>Close <IconCross/>
                </button>
            </FixedHolder>
            {/*<InfoButtons toggleInfo={toggleInfo}/>*/}
            <Container>
                <LogoHolder>
                    <Logo/>
                    <Square/>
                    <Circle/>
                </LogoHolder>
            </Container>
            <StaticQuery
                query={graphql`
                query InfoContentQuery {
                    contentfulInfo {
                          headline {
                            raw
                          }
                          hours {
                            raw
                          }
                          contact {
                            raw
                          }
                          location {
                            raw
                          }
                          clients {
                            raw
                          }
                          team {
                            raw
                          }
                          partners {
                            raw
                          }
                          contentHead {
                            raw
                          }
                          contentBody {
                            raw
                          }
                          banner {
                            gatsbyImageData
                            title
                          }
                          whoWeAre {
                            raw
                          }
                          howWeWork {
                            raw
                          }
                          services {
                            raw
                          }
                          shareText {
                            raw
                          }
                          embedText {
                            raw
                          }
                          createText {
                            raw
                          }
                          implementText {
                            raw
                          }
                          secondBanner {
                            gatsbyImageData
                            title
                          }
                          image {
                            gatsbyImageData
                            contentful_id
                          }
                          secondImage {
                            gatsbyImageData
                            title
                          }
                    }
                }
            `}
                render={data => (
                    <>
                        <Container>
                            <div className="headline-holder">
                                <RichTextConverter content={data.contentfulInfo.headline}/>
                            </div>
                            <WeirdDetailsHolder>
                                <DetailsInner ref={info} offset={offset}>
                                    <RichTextConverter content={data.contentfulInfo.location}/>
                                    <RichTextConverter content={data.contentfulInfo.contact}/>
                                    <RichTextConverter content={data.contentfulInfo.hours}/>
                                </DetailsInner>
                            </WeirdDetailsHolder>
                            {mobile && <RichTextConverter
                                content={data.contentfulInfo.contentHead}/>
                            }
                            {!mobile &&
                                <ContentHolder>
                                    <WeirdListHolder>
                                        <RichTextConverter content={data.contentfulInfo.clients}/>
                                        <RichTextConverter content={data.contentfulInfo.team}/>
                                        <RichTextConverter content={data.contentfulInfo.partners}/>
                                    </WeirdListHolder>
                                    <MainTextHolder>
                                        {!mobile &&
                                            <RichTextConverter content={data.contentfulInfo.contentHead}/>
                                        }
                                        <div className="image-grid">
                                            <div>
                                                <RichTextConverter content={data.contentfulInfo.contentBody}/>
                                                <RichTextConverter content={data.contentfulInfo.whoWeAre}/>
                                            </div>
                                            <GatsbyImage alt={data.contentfulInfo.image.title}
                                                         image={data.contentfulInfo.image.gatsbyImageData}/>
                                        </div>
                                    </MainTextHolder>
                                </ContentHolder>
                            }
                            {mobile &&
                                <>
                                    <Grid>
                                        <WeirdListHolder>
                                            <RichTextConverter content={data.contentfulInfo.clients}/>
                                        </WeirdListHolder>
                                        <RichTextConverter content={data.contentfulInfo.contentBody}/>
                                    </Grid>
                                    <GatsbyImage style={{width: '50%', marginBottom: '3rem', marginLeft: '-1rem'}}
                                                 alt={data.contentfulInfo.image.title}
                                                 image={data.contentfulInfo.image.gatsbyImageData}/>
                                    <Grid>
                                        <WeirdListHolder>
                                            <RichTextConverter content={data.contentfulInfo.team}/>
                                            <RichTextConverter content={data.contentfulInfo.partners}/>
                                        </WeirdListHolder>
                                        <RichTextConverter content={data.contentfulInfo.whoWeAre}/>
                                    </Grid>
                                </>
                            }
                        </Container>
                        <BannerImage>
                            <GatsbyImage alt={data.contentfulInfo.banner.title} style={{aspectRatio: '2/1'}}
                                         image={data.contentfulInfo.banner.gatsbyImageData}/>
                        </BannerImage>
                        <Container>
                            <ContentHolder>
                                <div>
                                </div>
                                <MainTextHolder>
                                    <div></div>
                                    <div className="image-grid">
                                        <RichTextConverter
                                            content={data.contentfulInfo.howWeWork}/>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        <GatsbyImage alt={data.contentfulInfo.secondImage.title}
                                                     image={data.contentfulInfo.secondImage.gatsbyImageData}/>
                                    </div>
                                    <div className="image-grid">
                                        <RichTextConverter content={data.contentfulInfo.services}/>
                                    </div>
                                    <Values>
                                        <Share/>
                                        <RichTextConverter content={data.contentfulInfo.shareText}/>
                                    </Values>
                                    <Values>
                                        <Embed/>
                                        <RichTextConverter content={data.contentfulInfo.embedText}/>
                                    </Values>
                                    <Values>
                                        <Create/>
                                        <RichTextConverter content={data.contentfulInfo.createText}/>
                                    </Values>
                                    <Values>
                                        <Implement/>
                                        <RichTextConverter content={data.contentfulInfo.implementText}/>
                                    </Values>
                                </MainTextHolder>
                            </ContentHolder>
                        </Container>
                        <BannerImage>
                            <GatsbyImage alt={data.contentfulInfo.secondBanner.contentful_id}
                                         style={{aspectRatio: '2/1'}}
                                         image={data.contentfulInfo.secondBanner.gatsbyImageData}/>
                        </BannerImage>
                        <ButtonHolder>
                            <a href="mailto:hello@studiotuntun.com">
                                <button className="button fancy">Learn more</button>
                            </a>
                            <LogoPlain/>
                            <small>All Rights Reserved © {new Date().getFullYear()}</small>
                        </ButtonHolder>
                    </>
                )}
            />
        </Holder>
    )
}

export default InfoContent;
