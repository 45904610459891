import React from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";
import IconCross from "../atoms/iconCross";

const Holder = styled.div`
  height: 100%;
  padding: 1rem;
`

const List = styled.ul`
  height: inherit;
  display: flex;
  flex-direction: column;
  gap: 0.5rem !important;

  li:last-child {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li:nth-child(1) {
    button {
      background-color: ${props => props.theme.colours.orange};
    }
  }

  li:nth-child(2) {
    button {
      background-color: ${props => props.theme.colours.pink};
    }
  }

  li:nth-child(3) {
    button {
      background-color: ${props => props.theme.colours.maroon};
      color: ${props => props.theme.colours.white};
    }
  }

  li:nth-child(4) {
    button {
      background-color: ${props => props.theme.colours.blue};
      color: ${props => props.theme.colours.white};
    }
  }

  li {
    justify-content: flex-end;

    .overlay-button {
      padding: 0 1.75rem;
      margin: 0;
      border-radius: 5rem;
      border: none;
      line-height: 1.75;
      ${props => props.theme.fluidType(4)};
      float: right;
      color: ${props => props.theme.colours.black};
    }

    a {
      padding: 0 !important;
      margin: 0 !important;
    }

    .grey {
      ${props => props.theme.fluidType(1)};
      color: ${props => props.theme.colours.black};
      background-color: ${props => props.theme.colours.grey};
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
  }
`

export default function MobileButtons({hide}) {

    return (
        <Holder>
            <List>
                <li>
                    <button className="button overlay-button" onClick={hide}>English</button>
                </li>
                <li>
                    <button className="button overlay-button" onClick={hide}>한국어</button>
                </li>
                <li>
                    <button className="button overlay-button" onClick={hide}>日本語</button>
                </li>
                <li>
                    <button className="button overlay-button" onClick={hide}>繁體</button>
                </li>
                <li>
                    <button className="button icon-large overlay-button grey eliza-mono"
                            onClick={hide}>Close <IconCross/>
                    </button>
                </li>
            </List>
        </Holder>
    )
}

MobileButtons.propTypes = {
    hide: PropTypes.func.isRequired,
    multiFunction: PropTypes.func.isRequired,
};
