import React from 'react';
import styled from "styled-components";

const Holder = styled.div`
  position: absolute;
  bottom: -2.2rem;
  right: 0;
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  column-gap: 0.5rem;

  button {
    ${props => props.theme.fluidType(-1.2)};
      //background-color: ${props => props.theme.colours.white} !important;
    background-color: ${props => props.theme.colours.orange};
    border: none;
    font-family: 'Graebenbach', Helvetica, Arial, sans-serif;
    border-radius: 2rem;
    z-index: 20;
    pointer-events: none;

    // &:hover {
      //   color: ${props => props.theme.colours.white};
    // }
  }
`

export default function LanguageButtons() {
    return (
        <Holder>
            {/*<button className="button en">English</button>*/}
            {/*<button className="button ko">한국어</button>*/}
            {/*<button className="button jp">日本語</button>*/}
            {/*<button className="button chn">繁體</button>*/}
            <button className="button fancy">Coming soon</button>
        </Holder>
    );
};
