import React from 'react';
import styled from 'styled-components';
import SubscribeForm from './SubscribeForm';
import Logo from '../../assets/combined-logo.inline.svg'
import Linkedin from "../../assets/linkedin.inline.svg"
import Linktree from '../../assets/linktree.inline.svg'
import Insta from '../../assets/insta.inline.svg'
import TorresStrait from '../../assets/ts-flag.inline.svg'
import Aboriginal from '../../assets/a-f.inline.svg'
import {Link} from "gatsby";

const Holder = styled.footer`
  padding: 1rem;
  color: ${props => props.theme.footerText};
  background-color: ${props => props.theme.footerBg};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 3fr 2fr;
  }

  a {
    text-decoration: none;
  }

  small {
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-1)};
    }
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  svg {
    height: auto;
    width: 70%;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      width: 100%;
    }
  }

  .acknowledgements {
    ${props => props.theme.fluidType(-3)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-1.5)};
    }
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-rows: unset;
    grid-template-columns: 3fr 2fr;
  }


  .flags {
    margin-top: 0.5rem;

    svg {
      margin-right: 0.5rem;
      width: calc(3.375rem / 1.75);
      height: calc(2.25rem / 1.75);
      @media ( ${props => props.theme.breakpoints.lg} ) {
        width: 54px;
        height: 36px;
      }
    }
  }
`

const ContactHolder = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 0.5rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    row-gap: 0.25rem;
  }

  .credits {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin-top: 0.5rem;
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: unset;
    }

    > * {
      ${props => props.theme.fluidType(-3)};
      @media ( ${props => props.theme.breakpoints.lg} ) {
        ${props => props.theme.fluidType(-1.5)};
      }
    }
  }
`

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .langs {
    display: none;
      // @media ( ${props => props.theme.breakpoints.lg} ) {
    //   display: flex;
    // }

    small {
      font-weight: bold;
      cursor: pointer;
      margin: 0;
      height: fit-content;
      border-radius: 2rem;
      padding: 0.15rem 0.25rem;
      color: ${props => props.theme.colours.black};

      &:hover {
        color: ${props => props.theme.colours.white};
      }
    }
  }

  > div {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;

    svg {
      path {
        fill: ${props => props.theme.footerText};
      }
    }
  }
`

function Footer() {
    return (
        <Holder>
            <Inner>
                <div>
                    <small className="acknowledgements">
                        We acknowledge the Wurundjeri Woi Wurrung people of the Kulin Nation, traditional custodians of
                        the
                        lands which we live and work on in the city of Naarm. We pay our respect to the Wurundjeri
                        Elders,
                        past and present.
                    </small>
                    <div className="flags">
                        <Aboriginal/>
                        <TorresStrait/>
                    </div>
                </div>
                <Link to='/'><Logo/></Link>
            </Inner>
            <ContactHolder>
                <Socials>
                    <div>
                        <a href="https://www.instagram.com/studio.tuntun/" rel="noreferrer noopener"
                           target="_blank"><Insta/></a>
                        <a href="https://linktr.ee/studiotuntun" rel="noreferrer noopener"
                           target="_blank"><Linktree/></a>
                        <a href="https://www.linkedin.com/company/studio-tuntun/" rel="noreferrer noopener"
                           target="_blank"><Linkedin/></a>
                    </div>
                    <div className="langs">
                        <small className="en">English</small>
                        <small className="ko">한국어</small>
                        <small className="jp">日本語</small>
                        <small className="chn">繁體</small>
                    </div>
                </Socials>
                <SubscribeForm/>
                <div className="credits">
                    <small>All Rights Reserved © {new Date().getFullYear()}</small>
                    <small>Site build by: <a href="https://publicwebsites.com/" rel="noreferrer noopener">Public
                        Websites</a></small>
                </div>
            </ContactHolder>
        </Holder>
    )
}

export default Footer;
