import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const ButtonHolder = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 70;
  pointer-events: none;

  button {
    border: 2rem solid ${props => props.theme.colours[props.border]} !important;
    background-color: ${props => props.theme.colours[props.bg]} !important;
    ${props => props.theme.fluidType(4)} !important;
    padding: 2rem !important;
    border-radius: 10rem !important;
    text-transform: uppercase;
    pointer-events: none;
    transform: rotate(60deg);
    font-family: "Graebenbach", Helvetica, Arial, sans-serif;
    @media ( ${props => props.theme.breakpoints.md} ) {
      transform: rotate(20deg);
    }
  }
`

export default function ComingSoon({bg, border}) {
    return (
        <ButtonHolder bg={bg} border={border}>
            <button className="button fancy">Coming Soon</button>
        </ButtonHolder>
    );
};

ComingSoon.propTypes = {
    bg: PropTypes.oneOf(['yellow', 'orange', 'peach', 'maroon']),
    border: PropTypes.oneOf(['yellow', 'orange', 'peach', 'maroon', 'blue'])
};

ComingSoon.defaultProps = {
    bg: 'yellow',
    border: 'maroon'
};
