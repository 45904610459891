import React from 'react';

function IconCross() {
    return (
        <svg width="60px" height="60px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Close</title>
            <g id="cross" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <line x1="4" y1="4" x2="56" y2="56" id="Path-41" stroke="#000" strokeWidth="7"/>
                <line x1="56" y1="4" x2="4" y2="56" id="Path-41" stroke="#000" strokeWidth="7"/>
            </g>
        </svg>
    )
}

export default IconCross;
