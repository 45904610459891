import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';

const pan = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

const Holder = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  white-space: nowrap;
`;

const Helper = styled.div`
  position: relative;
  visibility: hidden;
`;

const Inner = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  animation: ${pan} ${props=>props.speed} linear infinite;
`;

function TickerTape({children, speed}) {
  return (
    <Holder>
      <Helper>{children}</Helper>
      <Inner speed={speed}>
        {children}
      </Inner>
    </Holder>
  )
}

TickerTape.propTypes = {
  speed: PropTypes.string,
};

TickerTape.defaultProps = {
  speed: '10s',
};

export default TickerTape;
