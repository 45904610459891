import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';
import Star from '../../assets/star.inline.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Small = styled.small`
  colour: ${props => props.theme.colours.black};
`

const Dot = styled.div`
  width: 19px;
  height: 19px;
  background-color: ${props => props.theme.colours[props.colour]};
  margin-left: 0.5rem;
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem;

  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

function StatusInner({contentfulStatus}) {

    const [status, setStatus] = useState(null);
    const [colour, setColour] = useState(null);

    function autoStatus() {
        let d = new Date(); // current time
        let hours = d.getHours();
        let mins = d.getMinutes();
        let day = d.getDay();

        if (day === 6 || day === 0) {
            return {status: "Closed", colour: "maroon"}
        } else {
            if (hours >= 10 && hours < 18) {
                return {status: "Open", colour: "blue"}
            } else if (hours === 9 && mins >= 30 || hours === 18 && mins <= 30) {
                return {status: "In Transit", colour: "pink"}
            } else {
                return {status: "Closed", colour: "maroon"}
            }
        }
    }

    useEffect(() => {
        if (contentfulStatus !== "Auto") {
            if (contentfulStatus === 'In Transit') {
                setColour('pink');
            } else if (contentfulStatus === 'Open') {
                setColour('blue');
            } else if (contentfulStatus === 'Closed') {
                setColour('maroon');
            }
            setStatus(contentfulStatus);
        } else {
            const interval = setInterval(() => {
                let newStatus = autoStatus();
                setStatus(newStatus.status);
                setColour(newStatus.colour);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [contentfulStatus]);

    return (
        <>
            <Small>{status} &nbsp;&nbsp;:</Small>
            <Dot colour={colour}><Star/></Dot>
        </>
    )
}

StatusInner.propTypes = {
    contentfulStatus: PropTypes.string.isRequired,
};

export default StatusInner;
